import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import translations from "../translations.json";

function Price() {
    const navigate = useNavigate();
    const [translatedText, setTranslatedText] = useState({});

    useEffect(() => {
        const deviceLanguage = navigator.language.slice(0, 2);
        if (!translations.hasOwnProperty(deviceLanguage)){
            setTranslatedText(translations['en']);
        } else {
            setTranslatedText(translations[deviceLanguage]);
        }

    }, []);
    return (
        <div className="price">
            <main className="content" style={{backgroundImage: 'url(background.png)'}}>
                <h1>{translatedText.today_price}</h1>
                <div className={'line'}></div>

                <div className={'items'}>
                    <div className={'item'}>
                        <p>{translatedText.today}</p>
                        <div className={'calc'}>1px = 0.1 TON</div>
                    </div>
                    <span><img src={'/arrow.svg'}/></span>
                    <div className={'item'}>
                        <p>{translatedText.tomorrow}</p>
                        <div className={'calc'}>1px = 0.2 TON</div>
                    </div>
                </div>


                <div className="description">{translatedText.price_description}</div>

                <div className="close-btn">
                    <button onClick={() => navigate('/')}>OK</button>
                </div>
            </main>
        </div>
    );
}

export default Price;


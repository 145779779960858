import instance from '../api/interceptor';

export const UserService = {

  async getUser(id) {
    return instance.get('user/' + id);
  },

  async setUser(data) {
    return instance.post('user', data);
  },
};

import {useEffect, useState} from "react";
import {useMutation, useQuery} from "@tanstack/react-query";
import {UserService} from "../services/users.service";
import {useStore} from "../store/store";
import _ from "lodash";

export const useUserHook = (tgUser) => {
    const [loaded, setLoaded] = useState(false)
    const user = useStore((state) => state.user);
    const setUser = useStore((state) => state.setUser)

    const userQuery = useQuery({
        queryKey: ["users"],
        queryFn: () => UserService.getUser(229340498),
        enabled: false
    });

    const setUserQuery = useMutation({
        mutationFn: (data) => {
            return UserService.setUser(data);
        },
        onSuccess: (result) => {
            setUser(result.data);
        }
    });

    useEffect(() => {
        if (_.isEmpty(user)) {
            userQuery.refetch().then();
        }
    }, [user]);

    useEffect(() => {
        if (userQuery.isSuccess) {
            if (userQuery.data && userQuery.data.data.id) {
                setUser(userQuery.data.data);
            } else {
                setUserQuery.mutate();
            }
        }
    }, [userQuery.isSuccess]);

    if(!loaded) {
        return loaded;
    } else {
        return user;
    }
};
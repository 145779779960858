'use client'
import './styles/main.scss';
import './styles/info.scss';
import './styles/price.scss';
import './styles/wallet.scss';
import './styles/add.scss';
import './styles/tutorial.scss';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import OnePixel from "./pages/OnePixel";
import {useEffect} from "react";
import Main from "./components/layout/main";
import Side from "./components/layout/side";
import Price from "./pages/Price";
import Wallet from "./pages/wallet";
import Info from "./pages/Info";
import Tutorial from "./pages/Tutorial";
import {Provider} from "./providers/Provider";


const telegramWebApp = window.Telegram.WebApp;

function App() {

    useEffect(() => {
        telegramWebApp.disableVerticalSwipes();
        telegramWebApp.expand();
        console.log(telegramWebApp.version)
    }, []);

    return (
        <Provider>
            <Router>
                <Routes>
                    <Route path="/" element={
                        <Main>
                            <OnePixel/>
                        </Main>
                    }/>
                    <Route path="/price" element={
                        <Side><Price/></Side>
                    }/>
                    <Route path="/wallet" element={
                        <Side><Wallet/></Side>
                    }/>
                    <Route path="/info" element={
                        <Side><Info/></Side>
                    }/>
                    <Route path="/tutorial" element={
                        <Side><Tutorial/></Side>
                    }/>
                </Routes>
            </Router>
        </Provider>
    );
}


export default App;

import instance from '../api/interceptor';

export const PixelsService = {

    async getAllPixels() {
        return instance.get('pixels');
    },

    async setPixels(data) {
        return instance.post('pixels', data);
    },

};

import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import translations from "../translations.json";
import {TonConnectUIProvider} from "@tonconnect/ui-react";
import PaymentApp from "../components/payment";
import {UserService} from "../services/users.service";
import {useQuery} from "@tanstack/react-query";


function Wallet() {
    const navigate = useNavigate();
    const [translatedText, setTranslatedText] = useState({});

    const {data, isSuccess} = useQuery({
        queryKey: ["users"],
        queryFn: () => UserService.getUser(1),
    });

    // const handleInvite = () => {
    //     const userId = data.data.id;
    //     const newUserId = 3;
    //     const jsonString = JSON.stringify({userId: userId, newUserId: newUserId});
    //     const encodedString = btoa(jsonString);
    //     const inviteLink = `https://t.me/snftTestBot?startapp=${encodedString}`;
    //     const inviteLTelegramLink = `https://t.me/share/url?url=${inviteLink}`;
    //     window.open(inviteLTelegramLink, '_blank');
    //
    // };

    useEffect(() => {
        const deviceLanguage = navigator.language.slice(0, 2);
        if (!translations.hasOwnProperty(deviceLanguage)){
            setTranslatedText(translations['en']);
        } else {
            setTranslatedText(translations[deviceLanguage]);
        }

    }, []);
    return (
        <div className="wallet">
            <main className="content" style={{backgroundImage: 'url(background.png)'}}>
                <TonConnectUIProvider manifestUrl="http://localhost:3000/tonconnect-manifest.json">
                    <PaymentApp />
                </TonConnectUIProvider>
                <div className={'info'}>

                    <div className="current">
                        <div className={'el'}>
                            <p>{translatedText.your_pixels}</p>
                            <span>64px</span>
                        </div>
                        <img onClick={() => navigate('/')} src="/icons/plus.svg" alt=""/>
                    </div>


                    <div className="income">
                        <div className="el">
                            <p>{translatedText.summary_income}</p>
                            <span>259 <img src="/icons/ton.svg" alt=""/></span>
                        </div>
                    </div>
                </div>

                <div className="table">
                    <p className="title">{translatedText.return_or_invest}</p>
                    <div className="items">
                        <div className="item">
                            <div className="layer">
                                <p>{translatedText.layer} 10</p>
                                <span>300%</span>
                            </div>

                            <div className="pixels">
                                0 px
                            </div>

                            <div className="ton">
                                <img src="/arrow-gray.svg" className="arrow" alt=""/>
                                <p>0 <img src="/icons/ton.svg" alt=""/></p>
                            </div>

                            <div className="invite">
                                <img src="/icons/invite-opacity.svg" alt=""/>
                                <p>{translatedText.invite_friend}</p>
                            </div>
                        </div>
                        <div className="item">
                            <div className="layer">
                                <p>{translatedText.layer} 9</p>
                                <span>223.25%</span>
                            </div>

                            <div className="pixels">
                                0 px
                            </div>

                            <div className="ton">
                                <img src="/arrow-gray.svg" className="arrow" alt=""/>
                                <p>0 <img src="/icons/ton.svg" alt=""/></p>
                            </div>

                            <div className="invite">
                                <img src="/icons/invite-opacity.svg" alt=""/>
                                <p>{translatedText.invite_friend}</p>
                            </div>
                        </div>
                        <div className="item">
                            <div className="layer">
                                <p>{translatedText.layer} 8</p>
                                <span>99.75%</span>
                            </div>

                            <div className="pixels">
                                0 px
                            </div>

                            <div className="ton">
                                <img src="/arrow-gray.svg" className="arrow" alt=""/>
                                <p>0 <img src="/icons/ton.svg" alt=""/></p>
                            </div>

                            <div className="invite">
                                <img src="/icons/invite-opacity.svg" alt=""/>
                                <p>{translatedText.invite_friend}</p>
                            </div>
                        </div>
                        <div className="item">
                            <div className="layer">
                                <p>{translatedText.layer} 7</p>
                                <span>70.35%</span>
                            </div>

                            <div className="pixels">
                                0 px
                            </div>

                            <div className="ton">
                                <img src="/arrow-gray.svg" className="arrow" alt=""/>
                                <p>0 <img src="/icons/ton.svg" alt=""/></p>
                            </div>

                            <div className="invite">
                                <img src="/icons/invite-opacity.svg" alt=""/>
                                <p>{translatedText.invite_friend}</p>
                            </div>
                        </div>
                        <div className="item">
                            <div className="layer">
                                <p>{translatedText.layer} 6</p>
                                <span>37.35%</span>
                            </div>

                            <div className="pixels">
                                0 px
                            </div>

                            <div className="ton">
                                <img src="/arrow-gray.svg" className="arrow" alt=""/>
                                <p>0 <img src="/icons/ton.svg" alt=""/></p>
                            </div>

                            <div className="invite">
                                <img src="/icons/invite-opacity.svg" alt=""/>
                                <p>{translatedText.invite_friend}</p>
                            </div>
                        </div>
                        <div className="item">
                            <div className="layer">
                                <p>{translatedText.layer} 5</p>
                                <span>220%</span>
                            </div>

                            <div className="pixels">
                                0 px
                            </div>

                            <div className="ton">
                                <img src="/arrow-gray.svg" className="arrow" alt=""/>
                                <p>0 <img src="/icons/ton.svg" alt=""/></p>
                            </div>

                            <div className="invite">
                                <img src="/icons/invite-opacity.svg" alt=""/>
                                <p>{translatedText.invite_friend}</p>
                            </div>
                        </div>
                        <div className="item">
                            <div className="layer">
                                <p>{translatedText.layer} 4</p>
                                <span>180%</span>
                            </div>

                            <div className="pixels">
                                0 px
                            </div>

                            <div className="ton">
                                <img src="/arrow-gray.svg" className="arrow" alt=""/>
                                <p>0 <img src="/icons/ton.svg" alt=""/></p>
                            </div>

                            <div className="invite">
                                <img src="/icons/invite-opacity.svg" alt=""/>
                                <p>{translatedText.invite_friend}</p>
                            </div>
                        </div>
                        <div className="item">
                            <div className="layer">
                                <p>{translatedText.layer} 3</p>
                                <span>140%</span>
                            </div>

                            <div className="pixels">
                                32 px
                            </div>

                            <div className="ton">
                                <img src="/arrow-gray.svg" className="arrow" alt=""/>
                                <p>50 <img src="/icons/ton.svg" alt=""/></p>
                            </div>

                            <div className="invite" onClick={() => {console.log(111)}}>
                                <img src="/icons/invite.svg" alt=""/>
                                <p>{translatedText.invite_friend}</p>
                            </div>
                        </div>
                        <div className="item">
                            <div className="layer">
                                <p>{translatedText.layer} 2</p>
                                <span>120%</span>
                            </div>

                            <div className="pixels">
                                64 px
                            </div>

                            <div className="ton">
                                <img src="/arrow-gray.svg" className="arrow" alt=""/>
                                <p>80 <img src="/icons/ton.svg" alt=""/></p>
                            </div>

                            <div className="invite">
                                <img src="/icons/avatar.png" className="avatar" alt=""/>
                                <img src="/icons/check.svg" className="check" alt=""/>
                            </div>
                        </div>
                        <div className="item active">
                            <div className="layer">
                                <p>{translatedText.layer} 1</p>
                                <span>100%</span>
                            </div>

                            <div className="pixels">
                                164 px
                            </div>

                            <div className="ton">
                                <img src="/arrow-gray.svg" className="arrow" alt=""/>
                                <p>164 <img src="/icons/ton.svg" alt=""/></p>
                            </div>

                            <div className="invite">
                                <span>{translatedText.your_pixels}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
}

export default Wallet;


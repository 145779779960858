import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import translations from '../translations.json';

const telegramWebApp = window.Telegram.WebApp;


function Info() {
    const navigate = useNavigate();
    const [translatedText, setTranslatedText] = useState({});

    useEffect(() => {
        const deviceLanguage = navigator.language.slice(0, 2);
        if (!translations.hasOwnProperty(deviceLanguage)){
            setTranslatedText(translations['en']);
        } else {
            setTranslatedText(translations[deviceLanguage]);
        }

    }, []);

    return (<div className="info">
        <main className="content" style={{backgroundImage: 'url(info-background.png)'}}>
            <div className="step-0">
                <h1>
                    <div dangerouslySetInnerHTML={{__html: translatedText.info_title}}/>
                </h1>
                <div className="description">
                    {translatedText.info_title_desc}
                </div>
                <img className="line" src="/line.svg"/>
            </div>
            <div className="step-1">
                <div className="number">1</div>
                <div className="description">
                    <div dangerouslySetInnerHTML={{__html:translatedText.info_step1}}/>
                </div>
            </div>
            <div className="step-2">
            <div className="number">2</div>
                <div className="description">
                    <div>{ translatedText.info_step2}
                    </div>
                </div>
            </div>
            <div className="step-3">
                <div className="number">3</div>
                <div className="description">
                    <div>{ translatedText.info_step3}
                    </div>
                </div>
            </div>
            <div className="step-4">
                <div className="number">4</div>
                <div className="description">
                    <div>{ translatedText.info_step4}
                    </div>
                </div>
            </div>
            <div className="close-btn">
                <button onClick={() => navigate('/wallet')}>OK</button>
            </div>
        </main>
    </div>);
}

export default Info;


import React, {useEffect, useState} from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import classNames from "classnames";
import translations from "../../translations.json";

const Main =({children}) =>{
    const navigate = useNavigate();
    const location = useLocation();

    const [translatedText, setTranslatedText] = useState({});

    useEffect(() => {
        const deviceLanguage = navigator.language.slice(0, 2);
        console.log(navigator.language);

        if (translations[deviceLanguage]) {
            setTranslatedText(translations[deviceLanguage]);
        } else {
            setTranslatedText(translations['en']);
        }


    }, []);

    return(
        <div className={'main-layout'}>
            <header className="header" id="header">
                <div className="info" onClick={() => navigate('/price')}>
                    <img src="/info-icon.svg"/>
                    <div className="today-price">
                        <div className="text">{translatedText.today_price}</div>
                        <div className="price">1px = 0.1 TON</div>
                    </div>
                </div>
                <div className="wallet">
                    <div className="text">wallet</div>
                    <div className="wallet-account">
                        <div className="number">333</div>
                        <img src="/wallet-yellow.svg"/>
                    </div>
                </div>
            </header>
            {children}
            <footer className="footer" id="footer">
                <div className={'item'}>
                    <img src="airdrop-icon.svg"/>
                    <span>AIRDROP</span>
                </div>
                <div onClick={() => navigate('/')} className={classNames('item', {
                    active: location.pathname === '/'
                })}>
                    {location.pathname === '/' ? <img src="earn-icon-active.svg"/> : <img src="earn-icon.svg"/>}
                    <span>EARN</span>
                </div>
                <div onClick={() => navigate('/wallet')} className={classNames('item', {
                    active: location.pathname === '/wallet'
                })} onClick={() => navigate('/wallet')}>
                    {location.pathname === '/wallet' ? <img src="wallet-icon-active.svg"/> : <img src="wallet-icon.svg"/>}
                    <span>WALLET</span>
                </div>
            </footer>
        </div>
    )
}

export default Main;
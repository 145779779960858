import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import translations from '../translations.json';


function Info() {
    const navigate = useNavigate();
    const [translatedText, setTranslatedText] = useState({});

    useEffect(() => {
       const deviceLanguage = navigator.language.slice(0, 2);

        if (!translations.hasOwnProperty(deviceLanguage)){
            setTranslatedText(translations['en']);
        } else {
            setTranslatedText(translations[deviceLanguage]);
        }

    }, []);

    return (<div className="tutorial">
        <main className="content" style={{backgroundImage: 'url(tutorial-background.png)'}}>
            <div className="step-0">
                <h1 dangerouslySetInnerHTML={{__html:translatedText.info_title}}></h1>
                <div className="percent">
                    <div>{translatedText.tut_till}</div>
                    <span> 3000%</span></div>
                <div className="coins"><span> <img src="/wallet-yellow.svg"/> OneCoin </span> {translatedText.tut_sub_title}</div>
                <img class="line" src="/line.svg"/>
            </div>

            <div className="step-1">
                <div className="number">1</div>
                <div className="description">
                    <img src="/tut-1.png"/>
                    <div>{translatedText.tut_step1}</div>
                </div>
            </div>

            <div className="step-2">
                <div className="number">2</div>
                <div className="description">
                    <img src={translatedText.tut_step2_img}/>
                    <div dangerouslySetInnerHTML={{__html:translatedText.tut_step2}}>
                    </div>
                </div>
            </div>

            <div className="step-3">
                <div className="number">3</div>
                <div className="description">
                    <img src="/tut-3.png"/>
                    <div>{translatedText.tut_step3}
                    </div>
                </div>
            </div>

            <div className="step-4">
                <div className="number">4</div>
                <div className="description">
                    <img src={translatedText.tut_step4_img}/>
                    <div dangerouslySetInnerHTML={{__html:translatedText.tut_step4}}>
                    </div>
                </div>
            </div>
            <div className="bold">{translatedText.tut_bold}
            </div>

            <div className="close-btn">
                <button onClick={() => navigate('/wallet')}>OK</button>
            </div>
        </main>
    </div>);
}

export default Info;


import React, {useEffect, useRef, useState} from 'react';
import {useUserHook} from "../hooks/useUserHook";
import {useMutation, useQuery} from "@tanstack/react-query";
import {PixelsService} from "../services/pixels.service";


const telegramWebApp = window.Telegram.WebApp;
const tgUser = telegramWebApp.initDataUnsafe.user;

function OnePixel() {
    const [deviceLanguage, setDeviceLanguage] = useState(null);

    useEffect(() => {
        setDeviceLanguage(navigator.language || navigator.languages[0]);
        telegramWebApp.disableVerticalSwipes();
        telegramWebApp.expand();
    }, []);

    return (
        <div className="one-pixel">
            <main id={'area'} className="content">
                <div className="wrapper">
                    <iframe src="https://frame.snft.tech/" frameBorder="0"></iframe>
                </div>
            </main>
        </div>
    );
}

export default OnePixel;
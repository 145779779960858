import React, { useState } from 'react';
import { TonConnectButton, useTonConnectUI } from '@tonconnect/ui-react';
function PaymentApp() {
    const [tonConnectUI] = useTonConnectUI();
    const [amount, setAmount] = useState(10);

    const fixedReceiverAddress = 'key here';

    const handlePayment = async () => {
        if (!tonConnectUI.connected) {
            alert('Please connect your wallet first.');
            return;
        }

        try {
            await tonConnectUI.sendTransaction({
                messages: [
                    {
                        address: fixedReceiverAddress,
                        amount: (parseFloat(amount) * 1e9).toString(), // Сумма в нанотонах
                    },
                ],
                validUntil: Date.now() + 5 * 60 * 1000, // 5 минут на подтверждение
            });
            alert('Payment sent successfully!');
        } catch (error) {
            console.error('Payment error:', error);
            alert('Failed to send payment.');
        }
    };

    return (
        <div>
            <TonConnectButton />
        </div>
    );
}

export default PaymentApp;